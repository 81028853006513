import client from "./lib/client";
import axiosInstance from "../utils/axios";
// import { toast } from "react-hot-toast";

const updateCandidateProgressUrl = "/api/candidates/progress";
const activityMap = {
  WEB_CAM_ENABLED: "WEB_CAM_ENABLED",
  FULL_SCREEN_EXIT: "FULL_SCREEN_EXIT",
  ACTIVE_TAB_CHANGE: "ACTIVE_TAB_CHANGE",
  WEB_CAM_SHOT: "WEB_CAM_SHOT",
  CODE_SCREEN_SHOT_AND_ACTIVITY: "CODE_SCREEN_SHOT_AND_ACTIVITY",
};

export const getCandidateUploadUrlAndObjectUrl = async () => {
  const key = Date.now();
  const {
    data: {
      data: { objectUrl, preSignedUrl },
    },
  } = await client.get(
    `/api/utils/pre-signed-url-candidate?key=${key}&contentType={}`
  );
  return { key, objectUrl, preSignedUrl };
};

export const updateCandidateActivityWebCamEnabled = async (inviteId) => {
  return await client.put(updateCandidateProgressUrl, {
    type: activityMap.WEB_CAM_ENABLED,
    inviteId,
  });
};

export const updateCandidateActivityWebCamShot = async (
  inviteId,
  objectUrl
) => {
  return await client.put(updateCandidateProgressUrl, {
    type: activityMap.WEB_CAM_SHOT,
    inviteId,
    objectUrl,
  });
};

export const updateCandidateActivityFullScreenExit = async (inviteId) => {
  return await client.put(updateCandidateProgressUrl, {
    type: activityMap.FULL_SCREEN_EXIT,
    inviteId,
  });
};

export const updateCandidateActivityActiveTabChange = async (inviteId) => {
  return await client.put(updateCandidateProgressUrl, {
    type: activityMap.ACTIVE_TAB_CHANGE,
    inviteId,
  });
};

export const updateCandidateActivityCodeScreenShot = async (
  inviteId,
  objectUrl,
  activity
) => {
  return await client.put(updateCandidateProgressUrl, {
    type: activityMap.CODE_SCREEN_SHOT_AND_ACTIVITY,
    inviteId,
    objectUrl,
    activity,
  });
};

export const updateIsMicEnabled = async (isMicEnabled) => {
  return axiosInstance.put("/api/candidates/progress", { isMicEnabled });
};

export const updateIsWebCamEnabled = async (isWebCamEnabled) => {
  return axiosInstance.put("/api/candidates/progress", { isWebCamEnabled });
};

export const sendFullScreenExit = async () => {
  return axiosInstance.put("/api/candidates/progress", {
    alwaysInFullScreen: false,
  });
};

export const sendFocusChange = async () => {
  return axiosInstance.put("/api/candidates/progress", {
    alwaysTabActive: false,
  });
};

export const getPresignedUrl = async ({ key, contentType }) => {
  return axiosInstance.get(
    `/api/utils/pre-signed-url-candidate?key=${key}&contentType=${contentType}`
  );
};

export const sendCamShot = async ({ objectUrl }) => {
  // console.log('sendCamShot => ', objectUrl)
  // toast.success('sendCamShot => ', objectUrl)
  // return Promise.resolve(objectUrl)
  const timeStamp = getTimeStamp();
  return axiosInstance.put("/api/candidates/progress", {
    webCamShot: {
      url: objectUrl,
      timeStamp,
    },
  });
};

export const sentTestReview = async ({ testId, rate, comment = "" }) => {
  const timeStamp = getTimeStamp();
  return axiosInstance.put("/api/candidates/progress", {
    testReview: {
      testId,
      rate,
      comment,
      timeStamp,
    },
  });
};
export const sentAssessmentReview = async ({ rate, comment = "" }) => {
  const timeStamp = getTimeStamp();
  return axiosInstance.put("/api/candidates/progress", {
    assessmentReview: {
      rate,
      comment,
      timeStamp,
    },
  });
};

function getTimeStamp() {
  const currentDate = new Date();
  // Get the timestamp in milliseconds since the Unix epoch
  const timestamp = currentDate.getTime();
  return Math.floor(timestamp / 1000);
}

export const getFlowControl = async () => {
  return axiosInstance.get(`/api/candidates/flow-control`);
};
export const updateFlowControl = async (flowControl) => {
  return axiosInstance.put(`/api/candidates/progress`, { flowControl });
};

export const updateTimer = async (timer) => {
  return axiosInstance
    .put(`/api/candidates/progress`, { timer })
    .catch(console.error);
};

export const signInWithGoogle = async (payload) => {
  return axiosInstance.post(`/api/candidates/signup-with-google`, payload);
};

export const signInWithLinkedIn = async (payload) => {
  return axiosInstance.post(`/api/candidates/signup-with-linkedin`, payload);
};

export const getInviteToken = async (inviteId) => {
  return axiosInstance.get(`api/candidates/get-invite-token/${inviteId}`);
};

export const captureGeoLocation = async (coordinates) => {
  // toast.success(location)
  // return Promise.resolve(location)
  return axiosInstance.put("/api/candidates/capture-device-location", { coordinates });
};

export const verifyAssessmentLink = async (payload) => {
  return axiosInstance.post('api/candidates/verify-assessment-link', payload);
};
