// src/pages/LanguageSelection/languageSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedLanguage: "English",
  selectedLanguageId: "",
  activeLang: "",
  defaultLang: "",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    updateLanguage: (state, action) => {
      state.selectedLanguage = action.payload.selectedLanguage;
      state.selectedLanguageId = action.payload.selectedLanguageId;
      state.activeLang = action.payload.activeLang;
      state.defaultLang = action.payload.defaultLang;
    },
    setActiveLang: (state, action) => {
      state.activeLang = action.payload;
    },
    setDefaultLang: (state, action) => {
      state.defaultLang = action.payload;
    },
  },
});

export const { updateLanguage, setActiveLang, setDefaultLang } =
  languageSlice.actions;

export default languageSlice.reducer;
