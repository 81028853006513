import Cookies from "js-cookie";
import { PAGE_PATH } from "../router/path";
import { isMobile } from "react-device-detect";
import { number } from "yup";

export const isEmpty = (obj) => {
  for (let k in obj) {
    return true;
  }
  return false;
};

export const getUserMediaVideoConfig = (args = {}) => {
  const { deviceId, height, width } = args;

  const config =
    isMobile || deviceId || height || width
      ? {
          ...(isMobile && { facingMode: { exact: "user" } }),
          ...(deviceId && { deviceId: { exact: deviceId } }),
          ...(height && { height }),
          ...(width && { width }),
        }
      : true;

  return config;
};

export const setTimeToCookie = (inviteData) => {
  const inviteId = inviteData._id;
  const timer = inviteData.summary?.timer;
  removeUselessCookies(inviteId);
  inviteData.tests.forEach((test) => {
    const testId = test._id;
    const timeTotal = test.time;
    const timeLeft = Object.hasOwn(timer || {}, testId)
      ? timer[testId]
      : timeTotal;
    const { timeTotalKey, timeLeftKey } = getTimeCookieKeys(inviteId, testId);
    Cookies.set(timeTotalKey, timeTotal);
    Cookies.set(timeLeftKey, timeLeft);
  });
};

// export const setTimeToCookie = (inviteData) => {
//   const inviteId = inviteData._id;
//   //   const timeTotal = inviteData.assessment.time * 60;
//   const timeTotal = inviteData.assessment.time;
//   const timer = inviteData.summary?.timer;
//   const timeLeft = Object.hasOwn(timer || {}, "timeLeft")
//     ? timer.timeLeft
//     : timeTotal;

//   const { timeTotalKey, timeLeftKey } = getTimeCookieKeys(inviteId, testId);

//   removeUselessCookies(inviteId);
//   Cookies.set(timeTotalKey, timeTotal);
//   Cookies.set(timeLeftKey, timeLeft);
// };

export const getTimeFromCookie = (inviteId, testId) => {
  const { timeTotalKey, timeLeftKey } = getTimeCookieKeys(inviteId, testId);
  return {
    timeTotal: Cookies.get(timeTotalKey),
    timeLeft: Cookies.get(timeLeftKey),
  };
};

export const updateTimeLeftToCookie = (inviteId, testId, timeLeft) => {
  const { timeLeftKey } = getTimeCookieKeys(inviteId, testId);
  Cookies.set(timeLeftKey, timeLeft);
};

function getTimeCookieKeys(inviteId, testId) {
  const [timeTotalKey, timeLeftKey] = [
    `tt_${inviteId}_${testId}`,
    `tl_${inviteId}_${testId}`,
  ];
  return { timeTotalKey, timeLeftKey };
}

function removeUselessCookies(inviteId) {
  const existCookies = Cookies.get();
  for (let key in existCookies) {
    // if (key.startsWith("tt")) Cookies.remove(key);
    // if (key.startsWith("tl")) Cookies.remove(key);

    if (key.startsWith("tt_") && !key.startsWith(`tt_${inviteId}`))
      Cookies.remove(key);
    if (key.startsWith("tl_") && !key.startsWith(`tl_${inviteId}`))
      Cookies.remove(key);
  }
}

export const getTimeInSec = () => {
  return Math.trunc(Date.now() / 1000);
};

export const secondsToHMS = (seconds) => {
  const hours = Math.floor(seconds / 3600).toString();
  const minutes = Math.floor((seconds % 3600) / 60).toString();
  const remainingSeconds = String(seconds % 60);
  return (
    (Number(hours) > 0 ? `${hours.padStart(2, "0")}:` : "") +
    `${minutes.padStart(2, "0")}:${remainingSeconds.padStart(2, "0")}`
  );
};

export const startAssessment = async ({ id, token, target = "_blank" }) => {
  const __assessmentPath = PAGE_PATH.ASSESS_INVITE(id);
  if (token) Cookies.set("authToken", token, { path: __assessmentPath });
  window.open(`${window.location.origin}${__assessmentPath}`, target);
};

export const truncateStr = (str, num) => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  }
  return str;
};

export const secondsToMMSS = (seconds) => {
  const minutes = Math.floor(seconds / 60).toString();
  const remainingSeconds = String(seconds % 60);
  return `${minutes.padStart(2, "0")}:${remainingSeconds.padStart(2, "0")}`;
};
