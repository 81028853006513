import { createSlice } from "@reduxjs/toolkit";
import { STEPS } from "../../pages/AssmntStart/flow";
const __Terminated = STEPS.Terminated.slug;
// const initialState = {
//     assessment: {
//         inviteInfo: null,
//         candidateInfo: null,
//         assessmentInfo: null,
//         steps: {},
//         progress: {},
//         tests: {
//             testList: [],
//             totalTests: 0,
//             currTestIndex: 0,
//             currTestStatus: 'idle', // 'idle', 'loading', 'success', 'failed'
//         },
//         isFetching: false,
//         error: null
//     },
//     currentTest: {
//         testInfo: null,
//         steps: {},
//         progress: {},
//         practiceQ: [],
//         actualQ: [],
//         totalPQ: 0,
//         totalAQ: 0,
//         currPQIndex: 0,
//         currAQIndex: 0,
//         isFetching: false,
//         error: null,
//         isSaving: false,
//     }
// };

const initialStateV2 = {
  assessment: {
    __initialized: false,
    steps: {},
    progress: {},
    tests: {
      totalTests: 0,
      currTestIndex: 0,
    },
  },
  currentTest: {
    __initialized: false,
    index: 0,
    steps: {},
    progress: {},
    totalAQ: 0,
    currAQIndex: 0,
  },
};

export const flowControlSlice = createSlice({
  name: "flowControl",
  initialState: initialStateV2,
  reducers: {
    initializeState: (state, action) => {
      state.assessment = action.payload.assessment;
      state.currentTest = action.payload.currentTest;
    },
    fetchInvite: (state, action) => {
      state.assessment.isFetching = true;
      state.assessment.error = null;
    },
    inviteError: (state, action) => {
      state.assessment.isFetching = false;
      state.assessment.error = "Error";
    },
    initializeAssessment: (state, action) => {
      const { payload } = action;
      state.assessment.inviteInfo = payload.inviteInfo;
      state.assessment.candidateInfo = payload.candidateInfo;
      state.assessment.assessmentInfo = payload.assessmentInfo;
      state.assessment.steps = payload.steps;
      state.assessment.progress = payload.progress;
      state.assessment.tests = payload.tests;
      state.assessment.isFetching = false;
      state.assessment.error = null;
    },
    fetchTest: (state, action) => {
      state.currentTest.isFetching = true;
      state.currentTest.error = null;
      state.assessment.tests.currTestStatus = "loading";
    },
    TestError: (state, action) => {
      state.currentTest.isFetching = false;
      state.currentTest.error = "Error";
      state.assessment.tests.currTestStatus = "failed";
    },
    initializeTest: (state, action) => {
      const { payload } = action;
      state.currentTest.testInfo = payload.testInfo;
      state.currentTest.steps = payload.steps;
      state.currentTest.progress = payload.progress;
      state.currentTest.practiceQ = payload.practiceQ;
      state.currentTest.actualQ = payload.actualQ;
      state.currentTest.totalPQ = payload.totalPQ;
      state.currentTest.totalAQ = payload.totalAQ;
      state.currentTest.currPQIndex = 0;
      state.currentTest.currAQIndex = 0;
      state.currentTest.isFetching = false;
      state.currentTest.error = null;
      state.assessment.tests.currTestStatus = "success";
    },
    markAssessmentStepComplete: (state, action) => {
      const stepKey = action.payload;
      state.assessment.progress[stepKey].completed = true;
    },
    markTestStepComplete: (state, action) => {
      const stepKey = action.payload;
      state.currentTest.progress[stepKey].completed = true;
    },
    appendNextQuestionGroup: (state, action) => {
      const latestInvite = action.payload;
      const currentTestIndex = state.currentTest.index;
      const actualQuestions = latestInvite.tests[currentTestIndex].questions;
      const answeredQuestions = actualQuestions.filter(
        (q) => q.attemptStatus === "ANSWERED"
      );
      state.currentTest.actualQ = actualQuestions;
      state.currentTest.totalAQ = actualQuestions.length;
      state.currentTest.currAQIndex = answeredQuestions.length;
    },
    markTestComplete: (state, action) => {
      state.assessment.tests.currTestIndex += 1;
      state.currentTest.index += 1;
      state.currentTest.__initialized = false;
      // state.assessment.tests.currTestStatus = 'idle'
    },
    submitAnswer: (state, action) => {
      state.currentTest.isSaving = true;
    },
    markQuestionComplete: (state, action) => {
      const { payload } = action;
      if (payload?.isPractice) state.currentTest.currPQIndex += 1;
      else state.currentTest.currAQIndex += 1;
      state.currentTest.isSaving = false;
    },
    initializeAV2: (state, action) => {
      const { payload } = action;
      state.assessment = payload;
    },
    initializeTV2: (state, action) => {
      const { payload } = action;
      state.currentTest = payload;
    },
    terminateAssessment: (state, action) => {
      const progress = state.assessment.progress;
      for (let key in progress) {
        if (progress[key].completed) continue;
        progress[key].skipped = Boolean(key !== __Terminated);
      }
      state.assessment.progress = progress;
    },
  },
});
