import Warning from "./warning.svg";
import Help from "./help.svg";
import React from "./react.svg";
import XIcon from "./x-icon.svg";
import Java from "./java.svg";
import DotNet from "./dot-net.svg";
import Logo from "./logo.svg";
import BannerBg from "./banner-bg.svg";
import BannerImg from "./banner-img.svg";
import Compiling from "./compiling.svg";
import ArrowNext from "./arrow-next.svg";
import HireTripleLogo from "./hire-triple-logo.svg";
import TakingTestImg from "./taking-test-img.svg";
import Lens from "./lens.svg";
import Video from "./video.svg";
import Voice from "./voice.svg";
import VideoBlue from "./video-blue.svg";
import ArrowNextBlack from "./arro-next-black.svg";
import MetaDesignLogo from "./mds-logo.svg";
import ArrowNextWhite from "./arrow-next-white.svg";
import HirtripleCopy from "./hirtriple-copy.svg";
import LoginBannerMob from "./candidate-login-bg-mob.png";
import LoginBannerMobV2 from "./candidate-login-bg-mobV2.png";
import RightArrowSplit from "./right-arrow-split.svg";
import Google from "./google.svg";
import LinkedIn from "./linkedin.svg";
import Questa from "./questa.svg";
import PoweredByQuesta from "./poweredByQuesta.svg";
import GoogleLogo from "./google.png";
import LinkedInLogo from "./linkedIn.png";
import QuestaNewLogo from "./questa.png";
import CameraSlash from "./CameraSlash.svg";
import ArrowCircleRight from "./ArrowCircleRight.svg";
import UnorderedListBullet from "./UnorderedListBullet.svg";
import PlayButton from "./PlayButton.svg";
import scaleTeeth from "./scaleTeeth.svg";

const IMAGES = {
  Warning,
  Help,
  React,
  XIcon,
  Java,
  DotNet,
  Logo,
  BannerBg,
  BannerImg,
  Compiling,
  ArrowNext,
  HireTripleLogo,
  TakingTestImg,
  Lens,
  Video,
  Voice,
  VideoBlue,
  ArrowNextBlack,
  MetaDesignLogo,
  ArrowNextWhite,
  HirtripleCopy,
  LoginBannerMob,
  LoginBannerMobV2,
  RightArrowSplit,
  Google,
  LinkedIn,
  Questa,
  PoweredByQuesta,
  GoogleLogo,
  LinkedInLogo,
  QuestaNewLogo,
  CameraSlash,
  ArrowCircleRight,
  UnorderedListBullet,
  PlayButton,
  scaleTeeth,
};

export default IMAGES;
