export const STEPS = {
  UserConfirm: {
    slug: "UserConfirm",
    order: 1,
  },
  LanguageSelection: {
    slug: "LanguageSelection",
    order: 2,
  },
  Introduction: {
    slug: "Introduction",
    order: 3,
  },
  CameraMicSetup: {
    slug: "CameraMicSetup",
    order: 4,
  },
  GuidelineAssessment: {
    slug: "GuidelineAssessment",
    order: 5,
  },
  TestWindow: {
    slug: "TestWindow",
    order: 6,
  },
  Submitted: {
    slug: "Submitted",
    order: 7,
  },
  Terminated: {
    slug: "Terminated",
    order: 8,
  },
};

export const getTestProgressDTO = ({
  isPublicLink,
  isProctoringEnabled,
  hasMultipleLang,
}) => {
  return {
    [STEPS.UserConfirm.slug]: {
      skipped: false,
      completed: isPublicLink,
    },
    [STEPS.LanguageSelection.slug]: {
      skipped: false,
      completed: !hasMultipleLang,
    },
    [STEPS.Introduction.slug]: {
      skipped: false,
      completed: false,
    },
    [STEPS.CameraMicSetup.slug]: {
      skipped: !isProctoringEnabled,
      completed: false,
    },
    [STEPS.GuidelineAssessment.slug]: {
      skipped: false,
      completed: false,
    },
    [STEPS.TestWindow.slug]: {
      skipped: false,
      completed: false,
    },
    [STEPS.Submitted.slug]: {
      skipped: false,
      completed: false,
    },
    [STEPS.Terminated.slug]: {
      skipped: false,
      completed: false,
    },
  };
};
