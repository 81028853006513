import * as React from "react";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";

import { PAGE_PATH } from "./path";
import PageError from "../pages/Error/PageError";
import PageLoader from "../Components/Loader/PageLoader";

import AuthGuard from "../guards/AuthGuard";
import InviteGuard from "../guards/InviteGuard";

const Loadable = (Component) => (props) => {
  return (
    <React.Suspense fallback={<PageLoader />}>
      <Component {...props} />
    </React.Suspense>
  );
};

// Lazy loadable pages...
const InviteLinkPage = Loadable(
  React.lazy(() => import("../pages/LinkLandingPage/InviteLink"))
);
const PublicLinkPage = Loadable(
  React.lazy(() => import("../pages/LinkLandingPage/PublicLink"))
);
const HomPage = Loadable(React.lazy(() => import("../pages/Home/Home")));
const AssessmentPage = Loadable(
  React.lazy(() => import("../pages/AssmntStart/Assessment"))
);

export const router = createBrowserRouter([
  {
    path: PAGE_PATH.ROOT,
    element: (
      <AuthGuard>
        <HomPage />
      </AuthGuard>
    ),
  },
  {
    path: PAGE_PATH.LINK.ROOT,
    element: <Outlet />,
    children: [
      {
        path: PAGE_PATH.LINK.PUBLIC(":id"),
        element: <PublicLinkPage />,
      },
      {
        path: PAGE_PATH.LINK.INVITE(":id"),
        element: <InviteLinkPage />,
      },
      {
          path: '*',
          index: true,
          element: <PageError message='Invalid Assessment Link' />
      },
      {
        path: "*",
        element: <Navigate to={PAGE_PATH.ROOT} />,
      },
    ],
  },
  {
    path: PAGE_PATH.ASSESS_INVITE(":id"),
    element: (
      <InviteGuard>
        <AssessmentPage />
      </InviteGuard>
    ),
  },
  {
      path: '*',
      index: true,
      element: <PageError message='Invalid Assessment Link' />
  },
  // {
  //   path: "*",
  //   element: <Navigate to={PAGE_PATH.ROOT} />,
  // },
]);
