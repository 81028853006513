import * as React from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import { startAssessment } from "../utils/helper";
import { candidate as candidateService, testService, assessmentService } from "../services";
import jwtDecode from "jwt-decode";
import { PAGE_PATH } from "../router/path";

const ACTIONS = {
  SET_AUTH: "SET_AUTH",
  REMOVE_AUTH: "REMOVE_AUTH",
};

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  candidate: null,
  invites: null
};

const authServices = {
  Local: (values) => testService.verifyOtp(values),
  Google: (values) => candidateService.signInWithGoogle(values),
  LinkedIn: (values) => candidateService.signInWithLinkedIn(values),
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_AUTH:
      return {
        isInitialized: true,
        isAuthenticated: true,
        candidate: action.payload.candidate,
        invites: action.payload.invites,
      };
    case ACTIONS.REMOVE_AUTH:
      return {
        isInitialized: true,
        isAuthenticated: false,
        candidate: null,
        invites: null
      };
    default:
      return state;
  }
}

const context = React.createContext(initialState);

const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const authenticate = async ({ provider, payload }) => {
    try {
      const data = await authServices[provider](payload);
      toast.success('sign in successfully.')
      window.location.href = `${window.location.origin}${PAGE_PATH.LINK.INVITE(data.assesmentLinkId)}`
      console.log(`${window.location.origin}${PAGE_PATH.LINK.INVITE(data.assesmentLinkId)}`)
    } catch (err) {
      Cookies.remove('authToken')
      dispatch({ type: ACTIONS.REMOVE_AUTH });
    }
  };

  const logOut = () => {
    Cookies.remove('authToken')
    dispatch({ type: ACTIONS.REMOVE_AUTH });
  };

  return (
    <context.Provider value={{ ...state, authenticate, logOut }}>
      {children}
    </context.Provider>
  );
};

export const useAppAuth = () => React.useContext(context);

export default AuthProvider;
