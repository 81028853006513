import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    linkId: null,
    kind: '',
    status: '',
    client: null,
    assessment: null,
}

export const linkInfoSlice = createSlice({
    name: 'linkInfo',
    initialState: initialState,

    reducers: {
        setLinkInfo: (state, action) => {
            state._id = action.payload._id
            state.kind = action.payload.kind
            state.status = action.payload.status
            state.client = action.payload.client
            state.assessment = action.payload.assessment
        }
    }
})

export const getClientInfo = (state) => state.linkInfo.client
export const getAssessmentInfo = (state) => state.linkInfo.assessment
