export const PAGE_PATH = {
  ROOT: "/",
  LINKEDIN: "/linkedin",
  HOME: "/home",
  LINK: {
    ROOT: "/link",
    PUBLIC: (id) => `/link/public/${id}`,
    INVITE: (id) => `/link/invite/${id}`,
  },
  ASSESS_INVITE: (id) => `/assess/${id}`,
};
