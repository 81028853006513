import * as React from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { assessmentService } from "../services";
import { setTimeToCookie } from "../utils/helper";
import { assessmentSlice } from "../redux/assessment/slice";
import { linkInfoSlice } from "../redux/linkInfo/slice";
import * as flowControlActions from "../redux/flowControl/action";

import PageError from "../pages/Error/PageError";
import PageLoader from "../Components/Loader/PageLoader";
import { Navigate, useParams } from "react-router-dom";
import { PAGE_PATH } from "../router/path";
import Cookies from "js-cookie";
import { extractInviteData } from "../utils/extractInviteData";
import { updateLanguage } from "../pages/LanguageSelection/languageSlice";

const InviteGuard = ({ children }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const assessment = useSelector((state) => state.assessment);
  const languageState = useSelector((state) => state.language);
  const [isLanguageInitialized, setIsLanguageInitialized] =
    React.useState(false);

  // function handleInviteError(err) {
  //   Cookies.remove("authToken", { path: PAGE_PATH.ASSESS_INVITE(id) });
  //   const aid = localStorage.getItem(`iid_${id}`);
  //   localStorage.removeItem(`iid_${id}`);
  //   localStorage.removeItem(`aid_${aid}`);
  //   dispatch(
  //     assessmentSlice.actions.setAssessment({
  //       data: null,
  //       error: err.message,
  //     })
  //   );
  // }

  function handleInviteError(err) {
    Cookies.remove("authToken", { path: PAGE_PATH.ASSESS_INVITE(id) });
    const aid = localStorage.getItem(`iid_${id}`);
    localStorage.removeItem(`iid_${id}`);
    localStorage.removeItem(`aid_${aid}`);
    dispatch(
      assessmentSlice.actions.setAssessment({
        data: null,
        error: err.message,
      })
    );
  }

  const memoizedHandleInviteError = React.useCallback(handleInviteError, [
    id,
    dispatch,
  ]);

  React.useEffect(() => {
    async function fetchInvite() {
      try {
        const inviteData = await assessmentService.getInvite();
        const flowControl = inviteData.summary.flowControl;
        // console.log("flowControl: ", flowControl);
        dispatch(linkInfoSlice.actions.setLinkInfo(inviteData));
        await dispatch(
          assessmentSlice.actions.setAssessment({
            data: extractInviteData(inviteData),
            error: null,
          })
        );
        if (flowControl?.assessment?.__initialized) {
          await dispatch(flowControlActions.updateAV2(flowControl.assessment));
          await dispatch(flowControlActions.updateTV2(flowControl.currentTest));
        } else {
          await dispatch(flowControlActions.initializeAV2(inviteData));
        }

        setTimeToCookie(inviteData);

        // Initialize language state
        const defaultLanguage = inviteData.defaultLang || "English";
        const activeLanguage = inviteData.activeLang;

        // Find the language name corresponding to the active language ID
        const languageName =
          inviteData.assessment?.languages?.find(
            (lang) => lang._id === activeLanguage
          )?.name || defaultLanguage;

        dispatch(
          updateLanguage({
            selectedLanguage: languageName, // Use language name corresponding to activeLang
            selectedLanguageId: activeLanguage, // Use the actual ID for activeLang
            activeLang: activeLanguage,
            defaultLang: defaultLanguage, // Set defaultLang from inviteData
          })
        );

        setIsLanguageInitialized(true);
      } catch (err) {
        console.error(err);
        memoizedHandleInviteError(err);
      }
    }

    fetchInvite();
  }, [dispatch, memoizedHandleInviteError]);

  React.useEffect(() => {
    if (isLanguageInitialized) {
      const correctDefaultLang = assessment.__data?.defaultLang || "English";

      if (languageState.defaultLang !== correctDefaultLang) {
        console.log("Updating defaultLang to:", correctDefaultLang); // Debugging log
        dispatch(
          updateLanguage({
            ...languageState,
            defaultLang: correctDefaultLang,
          })
        );
      }
    }
  }, [dispatch, isLanguageInitialized, languageState, assessment.__data]);

  if (!assessment.__initialized) return <PageLoader />;

  if (!assessment.__data) {
    // toast.error("Invalid Invite Url");
    // window.location.href = window.location.origin;
    return <PageError message={"Something went wrong"} />;
  }

  return children;
};

export default InviteGuard;
