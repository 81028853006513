import * as React from "react";
import { Button, Typography } from "@mui/material";

import { CompanyLogoV1 } from "../../assets/images";
import { useAppAuth } from "../../contexts/AuthProvider";
import styles from "./authenticate-candidate.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { testService } from "../../services";
import toast from "react-hot-toast";

const VerifyOTP = ({ email, name }) => {
  const appAuth = useAppAuth();
  const linkInfo = useSelector((state) => state.linkInfo);
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState("");
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    let errMsg = "";
    try {
      setLoading(true);
      const _payload = { linkId: linkInfo._id, email, otp };
      await appAuth.authenticate({ provider: "Local", payload: _payload });
    } catch (err) {
      console.error(err);
    }
    setOtp("");
    setLoading(false);
    setError(errMsg);
  };

  const handleResendOtp = async () => {
    try {
      await testService.sendOtp({
        name: name,
        email: email,
        client: linkInfo?.client?._id,
      });
      toast.success("OTP send successfully");
    } catch (err) {
      console.error(err);
      toast.error(err?.message || "Failed to resend OTP");
    }
  };

  return (
    <form onSubmit={handleVerifyOTP}>
      <div className={`${styles.takingTestWrap}`}>
        {/* <div className={`mg-b-50 ${styles.logoWrapper}`}> */}
        <div className={`mg-b-50 tw-flex tw-items-center tw-justify-center`}>
          <img
            // className={`img-fluid`}
            // src={CompanyLogoV1}
            // alt="Questa-logo"
            src={linkInfo?.client.companyLogo}
            alt={linkInfo?.client.companyName}
            height={50}
            width={196}
            className="tw-h-[100px] tw-w-[194.11px]"
          />
        </div>
        <h5 className="text-danger text-center">{error}</h5>
        <div className={`mg-b-28 ${styles.userLoginHeading}`}>
          <Typography
            variant={"headingMedium"}
            component={"h2"}
            className={"mg-b-4"}
          >
            OTP Verification
          </Typography>
          <Typography
            component={"p"}
            variant={"body1"}
            className={`font-weight-medium`}
          >
            Thank you for registering with us. Please type the OTP as shared on
            your email {email}
          </Typography>
        </div>
        <div className={`mg-b-25`}>
          <label htmlFor={"otp"} className={`form-label`}>
            OTP
          </label>
          <input
            type="text"
            name="otp"
            id="otp"
            maxLength={20}
            placeholder={"Enter OTP"}
            className={`form-control form-control-md`}
            value={otp}
            onChange={(e) => setOtp(e.target.value.trim())}
            readOnly={loading}
          />
          {false && (
            <Typography
              component={"p"}
              variant={"body1"}
              color={"error"}
              className={"mg-t-4"}
            >
              This field is required
            </Typography>
          )}
        </div>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          disabled={loading || otp.length < 8}
          className="disabled:tw-text-gray-400"
          sx={{
            background: "primary",
            borderRadius: "8px",
            p: 1,
          }}
        >
          {loading ? "Verifying..." : "Submit"}
        </Button>
        <p
          className="tw-text-right tw-text-red-500 hover:tw-text-purple-600 hover:tw-cursor-pointer tw-mt-2.5"
          onClick={handleResendOtp}
        >
          Resend OTP
        </p>
      </div>
    </form>
  );
};

export default VerifyOTP;
