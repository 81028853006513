import { createTheme } from "@mui/material";

export const customTheme = createTheme({
  palette: {
    success: { main: "#29CC97" },
    error: { main: "#F64E60" },
    secondary: { main: "#9FA2B4" },
    primary: { main: "#7a1af0" },
    text: { primary: "#212121", secondary: "#B5B5C3" },
  },
  typography: {
    fontFamily: "Roboto",
    h1: {
      fontWeight: "600",
      fontSize: "3rem",
      fontFamily: "Poppins",
    },
    h2: {
      fontWeight: "600",
      fontSize: "1.875rem",
      fontFamily: "Poppins",
    },
    h3: {
      fontWeight: "600",
      fontSize: "1.5rem",
      fontFamily: "Poppins",
    },
    h4: {
      fontWeight: "600",
      fontSize: "1rem",
      fontFamily: "Poppins",
    },
    h5: {
      fontWeight: "500",
      fontSize: ".875rem",
      fontFamily: "Roboto",
    },
    body2: {
      fontSize: ".875rem",
    },
    headingMedium: {
      fontSize: "1.75rem",
      lineHeight: "2.0625rem",
    },
    headingAssessmentPortal: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "600",
      fontSize: "2.813rem",
      lineHeight: "3.404rem",
    },
    descriptionAssessmentPortal: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "1.625rem",
    },
    listHeadingAssessmentPortal: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "700",
      fontSize: "1.5rem",
      lineHeight: "1.625rem",
    },
    listItemsAssessmentPortal: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "1.21rem",
    },
    cameraAndMicDropdownLabel: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "600",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      color: "#7B27F0",
      margin: "0.125rem 4.0625rem 0.125rem 0.625rem",
    },
    testStartAlertTimeLeft: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "400",
      fontSize: "2.25rem",
      lineHeight: "2.723125rem",
      color: "#2C2C2C",
    },
    testStartAlertWarning: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "400",
      fontSize: "1.25rem",
      lineHeight: "1.625rem",
    },
    timerDisplayFont: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "700",
      fontSize: "2rem",
      lineHeight: "2.420625rem",
      color: "#333333",
    },
    timerTimeUnitFont: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "400",
      fontSize: "1.5rem",
      lineHeight: "1.815625rem",
      color: "#333333",
    },
    assessmentTimerAttempted: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "500",
      fontSize: "1rem",
      lineHeight: "1.21rem",
      color: "#FFFFFF",
    },
    currentQuestionNumerator: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "500",
      fontSize: "1.25rem",
      lineHeight: "1.5125rem",
      color: "#FFFFFF",
    },
    currentQustionSlash: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "400",
      fontSize: "1.25rem",
      lineHeight: "1.5125rem",
      color: "#FFFFFF",
    },
    currentQuestionDenominator: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "400",
      fontSize: "0.875rem",
      lineHeight: "1.05875rem",
      color: "#FFFFFF",
    },
    confettiCompletionHeading: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "600",
      fontSize: "2.8125rem",
      lineHeight: "3.40375rem",
      color: "#FFFFFF",
    },
    confettiCompletionDescription: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "1.625rem",
      color: "#FFFFFF",
    },
    overallAssessmentHeading: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "600",
      fontSize: "1.25rem",
      lineHeight: "1.5125rem",
      color: "#141414",
    },
    emojiLabel: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "500",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      color: "#2C2C2C",
    },
    assessmentCompleteYellowLabel: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "400",
      fontSize: "1.25rem",
      lineHeight: "1.625rem",
      color: "#2C2C2C",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          borderRadius: ".375rem",
          fontWeight: "600",
          boxShadow: "none",
        },
        contained: {
          border: `.0625rem solid`,
          padding: "5px 15px",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedError: {
          borderColor: `#F64E60`,
          "&:hover": {
            borderColor: `#F64E60`,
          },
        },
        containedPrimary: {
          color: `#fff`,
          borderColor: `#00A3FF`,
          "&:hover": {
            borderColor: `#00A3FF`,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: ".75rem",
          color: "#3F4254",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.15)",
          borderRadius: "5px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: `.375rem`,
          color: "#a947cb;",
          backgroundColor: "#a947cb;",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderColor: "#E4E6EF",
          fontFamily: "Roboto",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#B5B5C3",
        },
      },
    },
  },
});
